.card{
    width: 90%;
    max-width: 470px;
    background: linear-gradient(135deg, #09a4aa, #053faa);
    color: #fff;
    margin: 100px auto 0;
    border-radius: 20px;
    padding: 40px 35px;
    text-align: center;
}
.search{
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.search input{
    border: 0;
    outline: 0;
    background-color: transparent;
    background: linear-gradient(135deg, #ffff, #cecece);
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.12);
    padding: 10px 25px;
    color: #555;
    height: 60px;
    border-radius: 30px;
    flex: 1;
    margin-right: 16px;
    font-size: 18px;
}
.search button{
    border: 0;
    outline: 0;
    background: linear-gradient(135deg, #ffff, #cecece);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
}
.search button img{
    width: 33px;
    height: 40px;
    margin-left: 13px;
    margin-top: 2px;
}
.weather-icon{
    width: 170px;
    margin-top: 30px;
}

.weather h1{
    font-size: 75px;
    font-weight: 500;
}
.weather h2{
    font-size: 40px;
    font-weight: 400;
    margin-top: -10px;
}
.details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;
}
.col{
    display: flex;
    align-items: center;
    text-align: left;
}
.col img{
    width: 40px;
    margin-right: 10px;
}
.wind-pic{
    margin-left: 1.5rem;
}
