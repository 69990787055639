@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    @property --color-a{
        syntax: "<colo>";
        inherits: true;
        initial-value: transparent;
    }
    @property --color-b{
        syntax: "<colo>";
        inherits: true;
        initial-value: transparent;
    }
    @property --color-c{
        syntax: "<colo>";
        inherits: true;
        initial-value: transparent;
    }
}

::-webkit-scrollbar{
    width: 8px;
}
::-webkit-scrollbar-track{
    background:#282c34;
    
}
::-webkit-scrollbar-thumb{
    background-color: #2f3239;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover{
    background-color: #dadada;
}

*{
    scroll-behavior: smooth;
}

.sidebar {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
.logo{
    max-width: 100px;
    height: auto;
    border-radius: 40%;
    margin-right: 20px;
}
.maintitle{
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: -webkit-linear-gradient(80deg, #662549 30%, #451952 60%, #1d1a39 99%);
}
.gray-radient{
    background: -webkit-linear-gradient(125deg, #183345 10% ,#2c3e50 33%, #282c34 79%);
}
.active{
    color: darkorange
}
.id-link-{
    color:blueviolet;
}

.app:hover {
    background: linear-gradient(45deg, #1d1a39,#4a4e586a,#2c3e50,#282c34 );
	animation: gradient 5s ease infinite;
    background-size: 200% 200%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

